import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { KipoService } from '@core/kipo.service';
import { clientTypesEnum } from '@enums/identificationTypes.enum';
import { BaseComponent, BootService } from '@impesa/ngx-core';
import {
  KycService,
  PreValidationFunction,
  PreValidationObservable,
  PreValidationResult,
} from '@impesa/ngx-kyc';
import {
  Billing,
  CreateBillingDto,
} from '@impesa/ngx-kyc/lib/models/profile/profile.model';
import { ClientType } from '@models/client-type';
import { CountrySettings } from '@models/settings/country-settings.interface';
import { lastValueFrom, map, Observable } from 'rxjs';

import { GlobalVarsService } from '../../shared/services/global-vars/global-vars.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent extends BaseComponent implements OnInit {
  public _identificationType!: string;
  _billingIdType!: string;

  getIdNumberType: boolean = false;

  public tabDefaultPosition: boolean = true;

  public showAttachment: boolean = false;
  public showAlert: boolean = false;
  public showBilling: boolean = true;

  public countrySettings!: CountrySettings;
  public template: any;

  idNumberType!: ClientType;
  masket!: string;

  profileForm: UntypedFormGroup = new UntypedFormGroup({});
  billingForm: UntypedFormGroup = new UntypedFormGroup({});

  billingClass: any;
  postBillingClass: any;
  userProfile!: any;
  patchUserProfile!: any;
  public clientTypesList: any;
  public disableBillingValue!: boolean;
  public clientType: string = '';
  private completeKyc!: string;

  selected = new UntypedFormControl(0);

  public showProfile = false;
  public showKyc = false;
  public readOnly = true;
  public isCustomBilling = true;
  public isMobile: boolean = window.innerWidth < 768;

  public identificationTypesAllowed!: any;
  public userName!: string;

  public prevalidationFn: PreValidationFunction = (
    value: any
  ): PreValidationObservable => {
    let validationRespose: PreValidationResult;
    const IDNumber = value?.IDNumber;

    return this.kipoService.checkIdNumberAvailable(IDNumber).pipe(
      map((response: any) => {
        if (response) {
          validationRespose = {
            isValid: false,
            errorMessages: [
              this.translate('verification.IDENTIFICATION_NUMBER_EXIST'),
            ],
          };
        } else {
          validationRespose = {
            isValid: true,
          };
        }

        return validationRespose;
      })
    ) as unknown as PreValidationObservable;
  };

  constructor(
    private readonly actRouter: ActivatedRoute,
    private readonly activatedRoute: ActivatedRoute,
    private readonly globalService: GlobalVarsService,
    private readonly kipoService: KipoService,
    private readonly kycService: KycService,
    boot: BootService
  ) {
    super(boot);

    this.activatedRoute.data.subscribe((response: any) => {
      this.readOnly = response.data?.kycData?.readOnly;

      this.countrySettings = response.countrySettingsResolve.countrySettings;
      this.identificationTypesAllowed = response.data?.identificationTypes;
      this.sessionPropsService.sessionSubject.subscribe(res => {
        this.userName = res?.access_token?.username;
      });
    });
    this.kipoService.getCountryContent().subscribe((template: any) => {
      this.template = template;
    });
  }

  ngOnInit() {
    this.actRouter.queryParamMap.subscribe({
      next: (params: any) => {
        this.completeKyc = params.get('completeKyc') || '';
      },

      error: (err: any) => {
        this.globalService.errorMessage(err.error.message || err.message);
      },
    });

    this.userProfile = this.globalService.getProfile();
    if (this.userProfile?.identificationNumber) {
      this.isCustomBilling = false;
    }

    this.validateShowForms();
    this.initProfileChangesEvent();
    if (!this.userProfile) {
      this.showAlert = true;
    }
    if (!this.userProfile?.identificationType) {
      this.getIdNumberType = true;
    }
    this.clientType = this.userProfile?.clientType;
    this.kipoService.getClientTypes().subscribe({
      next: (result: ClientType[]) => {
        this.clientTypesList = result;
      },
      error: err => {
        this.globalService.errorMessage(err.error.message || err.message);
      },
    });

    this.billingClass = this.globalService.getBilling();
  }

  showAlertPanels(value: boolean) {
    this.showAlert = value;
  }

  async validateShowForms(clientType?: string) {
    this.showKyc = false;
    switch (this.userProfile?.clientType || clientType) {
      case clientTypesEnum.BUSINESS:
        this.showProfile = this.countrySettings.showBusinessProfile;

        if (this.showProfile) {
          if (this.isBasicFormCompleted()) {
            this.showKyc = this.countrySettings.showBusinessKyc;
          }
        } else {
          this.showKyc = this.countrySettings.showBusinessKyc;
        }
        break;

      case clientTypesEnum.PERSON:
        this.showProfile = this.countrySettings.showPersonProfile;

        if (this.showProfile) {
          if (this.isBasicFormCompleted()) {
            this.showKyc = this.countrySettings.showPersonKyc;
          }
        } else {
          this.showKyc = this.countrySettings.showPersonKyc;
        }
        break;
      default:
        break;
    }
    if (this.completeKyc) {
      this.selected.setValue(0);
    }
  }

  initProfileChangesEvent() {
    this.globalService.getUserProfile().subscribe({
      next: () => {
        this.validateShowForms();
      },
      error: (err: any) => {
        this.globalService.errorMessage(err.error.message || err.message);
      },
    });
  }

  isBasicFormCompleted(profile?: any): boolean {
    this.userProfile = profile || this.globalService.getProfile();
    if (this.userProfile?.identificationType) {
      return true;
    }
    return false;
  }

  async getCountTransactionHistory() {
    let totalTransaction: number = 0;
    this.kipoService.getTransactionsHistory().subscribe(transactions => {
      if (transactions) {
        totalTransaction = transactions.transactions.length;
      }
    });

    return totalTransaction;
  }

  billingGroup() {
    return this.profileForm.get('billing') as UntypedFormGroup;
  }

  radioChange(item: any) {
    this.idNumberType = item;
    this.clientType = item.code;
  }

  saveIdNumberType() {
    this.getIdNumberType = false;
    if (this.idNumberType.code === clientTypesEnum.BUSINESS) {
      this.validateShowForms(clientTypesEnum.BUSINESS);
    }

    if (this.idNumberType.code === clientTypesEnum.PERSON) {
      this.validateShowForms(clientTypesEnum.PERSON);
    }
  }

  validateSave() {
    if (this.profileForm.get('useInBilling')?.value) {
      return !this.profileForm.valid;
    }
    return !(this.profileForm.valid && this.billingForm.valid);
  }

  disableBilling(e: any) {
    this.disableBillingValue = e;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  tabChanged(e: any) {
    this.tabDefaultPosition = false;
    if (this.isBusiness()) {
      return this.tabDefaultPosition;
    }
    this.tabDefaultPosition = true;
    return this.tabDefaultPosition;
  }

  saveKycBusinessEvent(kyc: any) {
    if (kyc) {
      const access_token = this.globalService.getToken();
      this.kipoService.getUserData(access_token).subscribe(_userInfo => {
        if (_userInfo) {
          this.globalService.setProfile(_userInfo);
        }
        this.router.navigate(['/app/withdraw']);
      });
    }
  }

  public isPerson() {
    return (
      this.idNumberType?.code === clientTypesEnum.PERSON ||
      this.userProfile?.clientType === clientTypesEnum.PERSON
    );
  }

  public isBusiness() {
    return (
      this.idNumberType?.code === clientTypesEnum.BUSINESS ||
      this.userProfile?.clientType === clientTypesEnum.BUSINESS
    );
  }

  public kycSaved(kyc: any) {
    this.kipoService.checkIdNumberAvailable(kyc.identification).subscribe({
      next: async (response: any) => {
        if (response) {
          const userUpdateRequest: any = {
            identificationType: kyc.identificationType,
            identificationNumber: kyc.identification,
            clientType: kyc.clientType,
          };

          const response = await lastValueFrom(
            this.kipoService.putUserDataV2(userUpdateRequest)
          ).catch(err => {
            this.globalService.errorMessage(err.error.message || err.message);
          });

          if (response) {
            this.globalService.setProfile(response);
            this.router.navigate(['/app/withdraw']);
          }
        } else {
          const userCreateRequest: any = {
            username: this.userName,

            identificationType: kyc.identificationType,
            identificationNumber: kyc.identification,
            clientType: kyc.clientType,
          };

          const response: any = await lastValueFrom(
            this.kipoService.postUserDataV2(userCreateRequest)
          ).catch(err => {
            this.globalService.errorMessage(err.error.message || err.message);
          });

          if (response) {
            this.globalService.setProfile(response);
            const billingRequest: CreateBillingDto = {
              email: response.username,
              fullName: response.fullName,
              identification: response.identificationNumber,
              phone: response.phoneNumber,
              address: response.address,
              clientType: response.clientType,
              isCustom: false,
            };
            const request = this.kycService.addBillingAccount(
              billingRequest
            ) as unknown;
            await lastValueFrom(request as Observable<Billing>).catch(err => {
              this.globalService.errorMessage(err.error.message || err.message);
            });
            this.router.navigate(['/app/withdraw']);
          }
        }
      },
      error: (err: any) => {
        this.globalService.errorMessage(err.error.message || err.message);
      },
    });
  }
}
