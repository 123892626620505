export enum UserStatusesEnum {
  ACT = 'ACT',
  DEA = 'DEA',
  BLK = 'BLK',
  APR = 'APROV',
  REJ = 'REJTD',
  REV = 'REVID',
  REO = 'RETURO',
  REC = 'RETURC',
  DEN = 'DENID',
  REG = 'REGIS',
  COM = 'CMPLT',
  RVS = 'REVIDS',
  RVO = 'REVIDO',
  ESC = 'ESCAL',
  CLS = 'CLOSED',
}
